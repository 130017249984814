export const Colors = {
  PRIMARY: "#5B5EF5",
  DARK_PRIMARY: "#0B0EC3",
  LIGTH_PRIMARY: "#4278D0",
  TEXT_ICONS: "#FBFFF1",
  ACCENT: "#67D5B8",
  LIGTH_ACCENT: "#67C6BE",
  PRIMARY_TEXT: "#212121",
  SECONDARY_TEXT: "#757575",
  DIVIDER_COLOR: "#BDBDBD",
  DARK_BUTTON: "rgb(63, 81, 181)",
  GREY_LIGHT: "#fafafa",
  DANGER: "#BF2F42",
  LIGTH: "#E56767",
};
